<template>
  <section class="profilePageLayout">
    <template v-for="(section, index) in sections">
      <section v-if="$slots[section.name]" :key="index" :style="section.style">
        <slot :name="section.name"></slot>
      </section>
    </template>
    <div class="profilePageLayout__button">
      <BaseButton class="profilePageLayout__back" :icon_style="{ height: '12px', width: '8px' }" icon="arrow-left" :label="$t('BACK')" @click.native="$router.push({ name: 'home' })" />
      <BaseButton v-if="showSave" class="profilePageLayout__save" :label="$t('SAVE_CHANGES')" type="default" @click.native="$emit('user:save')" />
    </div>
  </section>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';

/**
 * Component used to build the profile page layout.
 *
 * @displayName ProfilePageLayout
 */
export default {
  name: 'ProfilePageLayout',
  components: {
    BaseButton
  },
  props: {
    /**
     * Array of columns sections of the page with defined property
     */
    sections: {
      default: () => [],
      type: Array,
      required: true,
      /**
       * The validator checks if every section has the properties 'name'
       */
      validator: value => {
        const mandatory_keys = ['name'];
        let is_valid = true;
        for (const section of value) {
          const keys = Object.keys(section);
          is_valid = mandatory_keys.every(key => keys.includes(key));

          if (!is_valid) return;
        }

        return is_valid;
      }
    },
    /**
     * Set to true to show the save changes button
     */
    showSave: { default: false, type: Boolean }
  }
};
</script>

<style lang="scss" scoped>
.profilePageLayout {
  $padding-bottom: 40px;
  $padding-horizontal: 170px;

  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 80px $padding-horizontal $padding-bottom $padding-horizontal;
  position: relative;

  > section {
    flex: 1;
    height: 100%;
    max-height: 100%;

    ~ section {
      margin-left: 38px;
    }
  }
  &__button {
    bottom: 30px;
    display: flex;
    height: 46px;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - 340px);
  }
}
@include responsive($max: md) {
  .profilePageLayout {
    background: var(--main-bg-color);
    flex-direction: column;
    height: auto;
    padding: 30px;
    padding-bottom: 76px;
    &__button {
      bottom: 30px;
      width: calc(100% - 60px);
    }
    > section {
      ~ section {
        margin-left: 0;
        > .personalArea__title {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
