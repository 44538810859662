<template>
  <ProfilePageLayout class="paymentDetails" :sections="sections" :show-save="showSave" @user:save="updateUser">
    <template #column1>
      <p class="paymentDetails__title">{{ $t('PAYMENT_DETAILS') }}</p>
      <BaseInput v-model="bank.bankName" class="fs-12" :label="$t('BANK.BANK_NAME')" />
      <BaseInput v-model="bank.accountName" class="fs-12" :label="$t('BANK.ACCOUNT_NAME')" />
      <BaseInput v-model="bank.accountNumber" class="fs-12" :label="$t('BANK.ACCOUNT_NUMBER')" />
      <BaseInput v-model="bank.iban" class="fs-12" :label="$t('BANK.IBAN')" />
    </template>
    <template #column2>
      <p class="mt-44 paymentDetails__secondColumn__p"></p>
      <BaseInput v-model="bank.branch" class="fs-12" :label="$t('BANK.BRANCH')" />
      <BaseSelect v-model="bank.country" :label="$t('BANK.COUNTRY')" :options="countryOptions" />
      <BaseInput v-model="bank.swift" class="fs-12" :label="$t('BANK.SWIFT')" />
      <BaseInput v-model="bank.bankCode" class="fs-12" :label="$t('BANK.BANK_CODE')" />
    </template>
  </ProfilePageLayout>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Store
import { mapGetters } from 'vuex';

// Base components
import BaseInput from '@base/BaseInput.vue';
import BaseSelect from '@base/BaseSelect.vue';

// Layout
import ProfilePageLayout from '@layout/ProfilePageLayout.vue';

// Utils
import countriesHelper from '@utils/countries/countries';

/**
 * Component used to show the user personal area based on role
 */
export default {
  name: 'PaymentDetails',
  components: {
    BaseInput,
    BaseSelect,
    ProfilePageLayout
  },
  data() {
    return {
      bank: {
        accountName: '',
        accountNumber: '',
        bankCode: '',
        bankName: '',
        branch: '',
        country: '',
        iban: '',
        swift: ''
      },
      countryOptions: countriesHelper.getGroupedContries(),
      sections: [{ name: 'column1' }, { name: 'column2' }],
      oldUser: {},
      newUser: {}
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    /**
     * Check if any change has been made
     */
    isUserEdited() {
      return JSON.stringify(this.user) !== JSON.stringify(this.newUser);
    },
    /**
     * True if some field has changed
     */
    showSave() {
      return JSON.stringify(this.oldUser) !== JSON.stringify(this.newUser);
    }
  },
  watch: {
    /**
     * Updating the new user instance every time the user updates some bank information
     */
    bank: {
      deep: true,
      handler: function (value) {
        this.$set(this.newUser.details, 'bank', value);
      }
    }
  },
  async created() {
    const { id, role } = this.user;

    const userResource = await UserApi.getUser({ id, role });

    if (userResource.statusCode === 200) {
      this.newUser = JSON.parse(JSON.stringify(userResource.data.user));
      this.oldUser = JSON.parse(JSON.stringify(userResource.data.user));

      /**
       * Retrieving the bank info from the user retrieved from BE
       */
      this.bank = {
        ...this.bank,
        ...this.oldUser.details.bank
      };
    }
  },
  methods: {
    /**
     * Method used to save the user update
     */
    async updateUser() {
      const { id } = this.user;
      delete this.newUser.status;

      const updateUserResource = await UserApi.updateUser({ id, payload: this.newUser });

      if (updateUserResource.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', this.$t('USER_UPDATED'));
        this.oldUser = JSON.parse(JSON.stringify(this.newUser));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.paymentDetails {
  &__title {
    @include inter-font($size: 16px, $bolded: true);
    margin-bottom: 20px;
    text-align: left;
  }
  @include responsive($max: md) {
    &__secondColumn__p {
      display: none;
    }
  }
}
</style>
