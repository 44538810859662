<template>
  <q-select
    class="baseSelect"
    :clearable="clearable"
    :bg-color="bgColor || ''"
    :filled="bgColor ? true : false"
    :hide-selected="useInput"
    :fill-input="useInput"
    dense
    menu-anchor="bottom left"
    outlined
    ref="baseSelect"
    :disable="disabled"
    :value="value"
    :error="error"
    :errorMessage="errorMessage"
    popup-content-class="baseSelectDropdown"
    :options="options"
    :option-label="optionLabel"
    :option-value="optionValue"
    :label="getLabel"
    :use-input="useInput"
    @clear="$emit('update:value', '')"
    @input="$emit('update:value', $event)"
    @filter="filterFn"
  >
    <template v-slot:option="scope">
      <q-item v-if="scope.opt.group === undefined" v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-item-label>{{ scope.opt[optionLabel] || scope.opt }}</q-item-label>
        </q-item-section>
      </q-item>
      <template v-if="scope.opt.group !== undefined">
        <q-item v-if="scope.opt.group" class="item-group" @click.native="$emit('update:value', scope.opt.group)">
          <q-item-section>
            <q-item-label>{{ scope.opt.group }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-for="(item, index) of scope.opt.labels" :key="`${index}__${scope.opt.group}`" class="item-group" @click.native="optionNativeClick(item)">
          <q-item-section>
            <q-item-label>{{ item.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </template>
  </q-select>
</template>

<script>
export default {
  name: 'BaseSelect',
  model: {
    event: 'update:value',
    prop: 'value'
  },
  props: {
    /**
     * Background color. If not passed the background will be transparent
     */
    bgColor: { default: '', type: String },
    /**
     * True if input should be clearable
     */
    clearable: { default: true, type: Boolean },
    /**
     * Set to true if want to be disabled
     */
    disabled: { default: false, type: Boolean },
    error: { default: false, type: Boolean },
    errorMessage: { default: '', type: String },
    filterFn: {
      default: function (value, update) {
        update(() => {});
        return;
      },
      type: Function
    },
    /**
     * Label of input field
     */
    label: { default: undefined, type: String },
    /**
     * Set to true if the field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /**
     * Select options
     */
    options: { default: () => [], type: Array },
    /**
     * Custom field to use as option label
     */
    optionLabel: { default: 'label', type: String },
    /**
     * Custom field to use as option value
     */
    optionValue: { default: 'value', type: String },
    /**
     * Set to true if you want to filter the select
     */
    useInput: { default: false, type: Boolean },
    /**
     * Value
     */
    value: { default: null, type: [String, Object, Number] }
  },
  computed: {
    /**
     * Returning the label with * if its mandatory
     */
    getLabel() {
      if (this.mandatory) return `${this.label}*`;
      return this.label;
    }
  },
  methods: {
    /**
     * Method used to close the select once otpion is selected
     */
    optionNativeClick(item) {
      this.$emit('update:value', item);
      this.$refs.baseSelect.hidePopup();
    }
  }
};
</script>

<style lang="scss">
.item-group {
  cursor: pointer;
  font-size: 16px !important;

  &:hover {
    background-color: #ddd;
  }

  &--is-disabled {
    color: var(--secondary-text-color);
    font-family: $inter-regular;
    font-size: 12px;
  }
}

.baseSelectDropdown {
  max-height: 200px;
}

.baseSelect {
  .q-field__control {
    border-radius: 10px;
    color: var(--main-text-color) !important;
    height: 45px !important;

    &::before {
      border: 1px solid var(--form-fields-border-color);
    }
  }

  .q-field__native {
    white-space: nowrap;
    font-size: 16px !important;
  }

  .q-field__label {
    color: var(--secondary-text-color);
  }
  .q-field__focusable-action {
    display: none !important;
  }
  .q-field--dense .q-field__label {
    font-size: 12px !important;
  }
}
.q-position-engine {
  max-height: 250px;
}
@include responsive($max: mobileL) {
  .baseSelect {
    .q-field--dense .q-field__label {
      font-size: 16px !important;
    }
    .q-field__native {
      white-space: nowrap;
      font-size: 16px !important;
    }
  }
}
</style>
